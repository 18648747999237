<template>
  <img alt="Vue logo" src="./assets/logo.png">
  <HelloWorld msg="简言 - 说出心里话"/>
  <div><a href="https://beian.miit.gov.cn/">京ICP备17073111号-1</a></div>
  
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
